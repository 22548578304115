/**
 * @typedef VersionType {'integration'|'comparison'|'mapping'|'crosswalk'|'dataType'|'app'}
 */

/**
 * @typedef Version
 * @property {number} id
 * @property {string} name
 * @property {number|null} publishedId
 * @property {string} documentId
 */

/**
 * @typedef VersionedDocument
 * @property {string} _id
 * @property {string?} name
 * @property {string?} displayName
 * @property {Version} version
 */

import { authorizedAxiosInstance } from '../axiosInstances';
import HttpStatusCodes from '../classes/HttpStatusCodes';
import { getGatewayUrl } from './envUtils';
import {
    copyIntegration,
    deleteIntegration,
    getIntegrationById,
    restoreIntegration,
} from './integrationDataRequestUtils';
import { copyMapping, deleteMapping, getMappingById, restoreMapping } from './rosettaDataRequestUtils';
import { copyMyDataType, deleteMyDataType, restoreMyDataType, getMyDataType } from './myDataTypeDataRequestUtils';
import { copyComparison, deleteComparison, getComparisonById, restoreComparison } from './comparisonsDataRequestUtils';
import { copyCrosswalk, deleteCrossWalk, getCrosswalkById, restoreCrosswalk } from './crosswalksDataRequestUtils';
import { deleteMyAppData, restoreMyApp } from './myAppDataRequestUtils';
import { showSnackBarErrorNotification } from './snackBarNotificationUtil';

/**
 * @param {VersionType} versionType
 * @param {string} urlType
 * @param {string|false} id
 * @returns {string}
 */
export const getUrlForVersionTypeById = (versionType, urlType, id = false) => {
    switch (versionType) {
        case 'integration':
            switch (urlType) {
                case 'getAllVersions':
                    return getGatewayUrl(`/integration/${id}/versions`);
                case 'publish':
                    return getGatewayUrl(`/integration/${id}/publish`);
                case 'newDraft':
                    return getGatewayUrl(`/integration/${id}/draft`);
                case 'refresh':
                    return `/integrations/${id}`;
                case 'getAll':
                    return getGatewayUrl('/integrations');
                case 'getAllVersionsByDocId':
                    return getGatewayUrl(`/integrations/versions/${id}`);
                default:
                    break;
            }
            break;
        case 'mapping':
            switch (urlType) {
                case 'getAllVersions':
                    return getGatewayUrl(`/rosetta/mapping/${id}/versions`);
                case 'publish':
                    return getGatewayUrl(`/rosetta/mapping/${id}/publish`);
                case 'newDraft':
                    return getGatewayUrl(`/rosetta/mapping/${id}/draft`);
                case 'refresh':
                    return `/rosetta/mapping/${id}`;
                case 'getAll':
                    return getGatewayUrl('/rosetta/mappings');
                case 'getAllVersionsByDocId':
                    return getGatewayUrl(`/rosetta/mappings/versions/${id}`);
                default:
                    break;
            }
            break;
        case 'crosswalk':
            switch (urlType) {
                case 'getAllVersions':
                    return getGatewayUrl(`/rosetta/crosswalk/${id}/versions`);
                case 'publish':
                    return getGatewayUrl(`/rosetta/crosswalk/${id}/publish`);
                case 'newDraft':
                    return getGatewayUrl(`/rosetta/crosswalk/${id}/draft`);
                case 'refresh':
                    return `/crosswalks/${id}`;
                case 'getAll':
                    return getGatewayUrl('/rosetta/crosswalks');
                case 'getAllVersionsByDocId':
                    return getGatewayUrl(`/rosetta/crosswalks/versions/${id}`);
                default:
                    break;
            }
            break;
        case 'dataType':
            switch (urlType) {
                case 'getAllVersions':
                    return getGatewayUrl(`/rosetta/dataType/${id}/versions`);
                case 'publish':
                    return getGatewayUrl(`/rosetta/dataType/${id}/publish`);
                case 'newDraft':
                    return getGatewayUrl(`/rosetta/dataType/${id}/draft`);
                case 'refresh':
                    return `/data-types/${id}`;
                case 'getAll':
                    return getGatewayUrl('/rosetta/dataTypes');
                case 'getAllVersionsByDocId':
                    return getGatewayUrl(`/rosetta/dataTypes/versions/${id}`);
                default:
                    break;
            }
            break;
        case 'comparison':
            switch (urlType) {
                case 'getAllVersions':
                    return getGatewayUrl(`/rosetta/comparison/${id}/versions`);
                case 'publish':
                    return getGatewayUrl(`/rosetta/comparison/${id}/publish`);
                case 'newDraft':
                    return getGatewayUrl(`/rosetta/comparison/${id}/draft`);
                case 'refresh':
                    return `/comparisons/${id}`;
                case 'getAll':
                    return getGatewayUrl('/rosetta/comparisons');
                case 'getAllVersionsByDocId':
                    return getGatewayUrl(`/rosetta/comparisons/versions/${id}`);
                default:
                    break;
            }
            break;
        case 'app':
            switch (urlType) {
                case 'getAllVersions':
                    return getGatewayUrl(`/app/${id}/versions`);
                case 'publish':
                    return getGatewayUrl(`/app/${id}/publish`);
                case 'newDraft':
                    return getGatewayUrl(`/app/${id}/draft`);
                case 'refresh':
                    return `/my-apps/${id}`;
                case 'getAll':
                    return getGatewayUrl('/apps');
                case 'getAllVersionsByDocId':
                    return getGatewayUrl(`/apps/versions/${id}`);
                default:
                    break;
            }
            break;
        default:
            break;
    }

    return '';
};

/**
 *
 * @param {VersionType} versionType
 * @param {string} id
 * @param {boolean} showDeleted
 * @param {boolean} onlyDeleted
 * @returns {Promise<Version[]|false>}
 */
export const getAvailableVersionsForTypeById = async (versionType, id, showDeleted = false, onlyDeleted = false) => {
    return authorizedAxiosInstance
        .request({
            method: 'get',
            url: getUrlForVersionTypeById(versionType, 'getAllVersions', id),
            params: {
                showDeleted: showDeleted,
                onlyDeleted: onlyDeleted,
            },
        })
        .then((response) => {
            if (response.status === HttpStatusCodes.SUCCESS) {
                return response.data;
            }

            return false;
        })
        .catch(() => {
            return false;
        });
};

/**
 * @param {VersionType} versionType
 * @param {string} id
 * @returns {Promise<true|string>}
 */
export const publishDraftDocument = async (versionType, id, changes, name) => {
    return authorizedAxiosInstance
        .request({
            method: 'post',
            url: getUrlForVersionTypeById(versionType, 'publish', id),
            data: { changes: changes, name: name },
        })
        .then((data) => {
            return data.status === HttpStatusCodes.SUCCESS;
        })
        .catch((error) => {
            return error?.response?.data?.message || error?.response?.data?.error || 'Failed to publish document';
        });
};

/**
 * @param {VersionType} versionType
 * @param {string} id
 * @param {string} name
 * @returns {Promise<IntegrationData|boolean>}
 */
export const createNewDraftDocument = async (versionType, id, name) => {
    return authorizedAxiosInstance
        .request({
            method: 'post',
            url: getUrlForVersionTypeById(versionType, 'newDraft', id),
            data: {
                name: name,
            },
        })
        .then((response) => {
            if (response.status === HttpStatusCodes.SUCCESS) {
                return response.data;
            }

            return false;
        })
        .catch(() => {
            return false;
        });
};

/**
 * @param {VersionType} versionType
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getDocumentsByVersionType = async (versionType) => {
    return authorizedAxiosInstance
        .request({
            method: 'get',
            url: getUrlForVersionTypeById(versionType, 'getAll', false),
        })
        .then((response) => {
            if (response.status === HttpStatusCodes.SUCCESS) {
                return response.data;
            }

            return false;
        })
        .catch(() => {
            return false;
        });
};

/**
 * @param {VersionType} versionType
 * @param {string} documentId
 * @returns {Promise<Array|false>}
 */
export const getVersionsByDocumentId = async (versionType, documentId) => {
    return authorizedAxiosInstance
        .request({
            method: 'get',
            url: getUrlForVersionTypeById(versionType, 'getAllVersionsByDocId', documentId),
        })
        .then((response) => {
            if (response.status === HttpStatusCodes.SUCCESS) {
                return response.data;
            }

            return false;
        })
        .catch(() => {
            return false;
        });
};

/**
 * @param {string} documentId
 * @returns {Promise<AxiosResponse<any> | boolean>}
 */
export const getActiveVersionedIntegration = async (documentId) => {
    return authorizedAxiosInstance
        .request({
            method: 'get',
            url: getGatewayUrl(`/integrations/versions/${documentId}/active`),
        })
        .then((response) => {
            if (response.status === HttpStatusCodes.SUCCESS) {
                return response?.data || {};
            }

            return false;
        })
        .catch(() => {
            return false;
        });
};

/**
 * @returns {function}
 */
export function getActionFunctionForActionAndType(actionType, versionType) {
    switch (actionType) {
        case 'get':
            switch (versionType) {
                case 'integration':
                    return getIntegrationById;
                case 'mapping':
                    return getMappingById;
                case 'dataType':
                    return getMyDataType;
                case 'comparison':
                    return getComparisonById;
                case 'crosswalk':
                    return getCrosswalkById;
            }
            break;
        case 'copy':
            switch (versionType) {
                case 'integration':
                    return copyIntegration;
                case 'mapping':
                    return copyMapping;
                case 'dataType':
                    return copyMyDataType;
                case 'comparison':
                    return copyComparison;
                case 'crosswalk':
                    return copyCrosswalk;
            }
            break;
        case 'delete':
            switch (versionType) {
                case 'integration':
                    return deleteIntegration;
                case 'mapping':
                    return deleteMapping;
                case 'dataType':
                    return deleteMyDataType;
                case 'comparison':
                    return deleteComparison;
                case 'crosswalk':
                    return deleteCrossWalk;
                case 'app':
                    return deleteMyAppData;
            }
            break;
        case 'restore':
            switch (versionType) {
                case 'integration':
                    return restoreIntegration;
                case 'mapping':
                    return restoreMapping;
                case 'dataType':
                    return restoreMyDataType;
                case 'comparison':
                    return restoreComparison;
                case 'crosswalk':
                    return restoreCrosswalk;
                case 'app':
                    return restoreMyApp;
            }
            break;
    }

    return () => {
        showSnackBarErrorNotification('Invalid type for submit');
    };
}

/**
 * @param {string} actionType
 * @param {string} versionType
 * @param {string} id
 * @param {string?} newName
 * @returns {Object|string}
 */
export function getActionParametersForType(actionType, versionType, id, newName = '') {
    switch (actionType) {
        case 'copy':
        case 'get':
            switch (versionType) {
                case 'integration':
                    return { integrationId: id, integrationName: newName };
                case 'mapping':
                    return { mappingId: id, mappingName: newName };
                case 'dataType':
                    return { dataTypeId: id, dataTypeName: newName };
                case 'comparison':
                    return { comparisonId: id, comparisonName: newName };
                case 'crosswalk':
                    return { crosswalkId: id, crosswalkName: newName };
            }
            break;
        case 'delete':
        case 'restore':
            switch (versionType) {
                case 'integration':
                    return id;
                case 'mapping':
                    return { mappingId: id };
                case 'dataType':
                    return { dataTypeId: id };
                case 'comparison':
                    return { comparisonId: id };
                case 'crosswalk':
                    return { crosswalkId: id };
                case 'app':
                    return { appId: id };
            }
            break;
    }
}

/**
 * @param {VersionType} versionType
 * @param {string} id
 * @returns {Promise<(function(*): Promise<unknown>)|*|(function(Object=): Promise<*>)|(function(Object): Promise<*>)|(function(Object=): Promise<*>)|(function(Object=): Promise<*>)|(function({integrationId: *, integrationName: *}): Promise<*>)|(function(Object=): Promise<*>)|(function(Object=): Promise<*>)|(function(Object=): Promise<*>)|(function(Object=): Promise<*>)|(function(*): Promise<unknown>)|(function({mappingId: String}): Promise<*>)|(function(Object=): Promise<*>)|(function(Object): Promise<*>)|(function(string): Promise<*>)|(function({mappingId: *, successCallback: *, errorCallback: *, finallyCallback: *}=): Promise<*>)|(function({dataTypeId: *, successCallback: *, errorCallback: *, finallyCallback: *}=): Promise<*>)|(function({comparisonId: *, successCallback: *, errorCallback: *, finallyCallback: *}=): Promise<*>)|(function({crosswalkId: *, successCallback: *, errorCallback: *, finallyCallback: *}=): Promise<*>)|(function({appId: *, successCallback: *, errorCallback: *, finallyCallback: *}): Promise<*>)|(function(): void)>}
 */
export async function getVersionedDocumentById(versionType, id) {
    return await getActionFunctionForActionAndType(
        'get',
        versionType,
    )(getActionParametersForType('get', versionType, id));
}
